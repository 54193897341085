import React, { useCallback, useState } from "react";
import { useModal } from "@ludens-reklame/react-modal";
import { useStore } from "@ludens-reklame/rubics-v3-react/store/hooks";
import { File, Image } from "@ludens-reklame/rubics-v3-sdk/file/types";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { createOrder } from "@ludens-reklame/rubics-v3-sdk/order";
import { useProfile } from "@ludens-reklame/rubics-v3-react/profile/hooks";
import { CartItem, InputCart } from "@ludens-reklame/rubics-v3-sdk/cart/types";
import { ProfileContactInfo } from "@ludens-reklame/rubics-v3-sdk/profile/types";
import { Link } from "@ludens-reklame/rubics-v3-sdk/link/types";
import { useCart } from "@ludens-reklame/rubics-v3-react/cart/hooks";
import { RichText } from "../../internal-components/richText/RichText.js";
import { arrow, quote } from "../../icons/svg.js";
import { getLinkProps } from "../../client-utils/getLinkProps.js";
import { Busy } from "../../internal-components/busy/busy.js";
import {
  Consents,
  SettableConsent,
} from "../../internal-components/consents/consents.js";
import { handleConsents } from "../../client-utils/handleConsents.js";
import { ProfileProps } from "../../types/profile.js";
import { renderTaxString } from "../../client-utils/renderTaxString.js";
import { ProductProps } from "../../types/general.js";

interface FormInput extends ProfileContactInfo {
  billingEmail?: string;
  billingFirstName?: string;
  billingLastName?: string;
  billingPhone?: string;
}

interface BlockBase {
  id: string;
  key: string;
}

interface Instructor extends BlockBase {
  key: "instructor";
  name?: string;
  title?: string;
  description?: string;
  image?: Image;
}

interface Testimonial extends BlockBase {
  image?: Image;
  text?: string;
  byline?: string;
}

interface HtmlBlock extends BlockBase {
  key: "html";
  html?: string;
}

interface LinkBlock extends BlockBase {
  key: "link";
  link?: Link;
}

type SidebarBlock = HtmlBlock | LinkBlock;

interface Props {
  isCourse?: boolean;
  subtitle?: string;
  sidebarTitle?: string;
  file?: File;
  priceText?: string;
  instructorsTitle?: string;
  instructors?: Instructor[];
  sidebarBlocks?: SidebarBlock[];
  testimonials?: Testimonial[];
  consents?: SettableConsent[];
  // For storybook
  cartReference?: string;
}

const Course: React.FC<Props> = ({
  isCourse,
  subtitle,
  sidebarTitle,
  file,
  priceText,
  instructorsTitle,
  instructors = [],
  sidebarBlocks = [],
  testimonials = [],
  consents = [],
  cartReference,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { profile } = useProfile<ProfileProps>();
  const context = useStore<{}, {}, {}, ProductProps>().context;
  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    context.product?._id
  );
  const { networking, addItem } = useCart();
  const { spawnModal } = useModal();
  const product = context.product;
  const group = context.group;
  const courseIsFree = !product?.price;
  const courseHasMemberPrice = (product?.priceEntries.length || 0) > 1;

  async function handleAddToCart() {
    if (!product || networking) {
      return;
    }

    const item = await addItem({
      product: product._id,
      quantity: 1,
      reference: cartReference,
    });

    if (item) {
      spawnModal(<AddToCartModal item={item} />);
    }
  }

  const handleBookCourse = useCallback(
    async (data: FormData) => {
      try {
        if (success || loading || !product) {
          return;
        }

        const input: FormInput = Object.fromEntries(data);
        const profession = data.get("profession") as string;
        const paymentReference = data.get("paymentReference") as string;
        const allergies = data.get("allergies") as string;
        const discountCode = data.get("discountCode") as string;

        setLoading(true);

        const cart: InputCart = {
          discountCodes: discountCode ? [discountCode] : [],
          items: [
            {
              product: selectedDate || product._id,
              quantity: 100,
              meta: [
                {
                  key: "allergies",
                  value: allergies || "",
                },
              ],
            },
          ],
          billing: {
            firstName: input.billingFirstName,
            lastName: input.billingLastName,
            phone: input.billingPhone,
            email: input.billingEmail,
            addressLine1: input.addressLine1,
            postcode: input.postcode,
            city: input.city,
            company: input.company,
            vat: input.vat,
          },
          shipping: {
            firstName: input.firstName,
            lastName: input.lastName,
            email: input.email,
            phone: input.phone,
          },
          meta: [
            {
              key: "profession",
              value: profession || "",
            },
            {
              key: "paymentReference",
              value: paymentReference || "",
            },
          ],
        };

        await handleConsents(input, context.url.pathname);
        const order = await createOrder(cart);

        if (!order) {
          throw new Error("Failed to create order");
        }

        setSuccess(true);
      } catch (error) {
        console.error(error);
        window.alert(
          "Noe galt skjedde, vennligst prøv igjen eller kontakt oss for hjelp."
        );
      } finally {
        setLoading(false);
      }
    },
    [product, courseIsFree, loading, success, selectedDate]
  );

  if (!product) {
    return null;
  }

  if (success) {
    return (
      <div className="vs-xxl mw-medium hs course-success">
        <div className="card">
          <div className="mw-small">
            <h1 className="h3">Takk for at du meldte deg på</h1>
            <p className="h5">
              Sjekk e-posten din for kvittering og mer informasjon.
            </p>
            <a className="button secondary" href="/">
              Gå til startsiden {arrow}
            </a>
          </div>
        </div>
      </div>
    );
  }

  const dateAttr = product.attributes.find((a) => a.key === "Dato");

  return (
    <div className="vs-xxl mw hs course">
      <div className="column">
        <div className="card">
          <h1 className="h5">
            {subtitle && <span className="h3">{subtitle}</span>}
            {product.name}
          </h1>
          <Gfx file={file} />
          {product.description && (
            <RichText className="block" html={product.description} />
          )}
          {product.extendedDescription && (
            <RichText className="block" html={product.extendedDescription} />
          )}
          <Price text={priceText} />
          <Instructors title={instructorsTitle} instructors={instructors} />
          <h2 className="h4">{isCourse ? "Påmelding" : "Kjøp"}</h2>
          {context.loggedIn && profile && (
            <div className="login-notice">
              <p className="h6">Velkommen tilbake, {profile?.firstName}</p>
            </div>
          )}
          {!context.loggedIn && courseHasMemberPrice && !courseIsFree && (
            <div className="login-notice">
              <p className="h6">
                Er du medlem?
                <br />
                Logg inn for å få medlemspris
              </p>
              <a
                className="button secondary"
                href={`/logg-inn?redirect=${context.url.pathname}`}
              >
                Logg inn {arrow}
              </a>
            </div>
          )}
          <Busy busy={loading || networking}>
            {isCourse ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleBookCourse(new FormData(e.currentTarget));
                }}
              >
                {product.variants.length > 0 && (
                  <fieldset>
                    <legend className="h6">Velg dato:</legend>
                    <label className="b3">
                      <input
                        name="date"
                        type="radio"
                        checked={selectedDate === product._id}
                        onChange={() => setSelectedDate(product._id)}
                      />
                      {dateAttr?.value || product.name}
                    </label>
                    {product.variants.map((variant) => (
                      <label key={variant._id} className="b3">
                        <input
                          name="date"
                          type="radio"
                          checked={selectedDate === variant._id}
                          onChange={() => setSelectedDate(variant._id)}
                        />
                        {variant.attributeValues.Dato || variant.name}
                      </label>
                    ))}
                  </fieldset>
                )}
                <h3 className="h6">Deltaker</h3>
                <label className="b3">
                  Fornavn:*
                  <input
                    className="b1"
                    type="text"
                    name="firstName"
                    autoComplete="given-name"
                    placeholder="Kari"
                    defaultValue={profile?.firstName}
                    required
                  />
                </label>
                <label className="b3">
                  Etternavn:*
                  <input
                    className="b1"
                    type="text"
                    name="lastName"
                    autoComplete="family-name"
                    placeholder="Nordmann"
                    defaultValue={profile?.lastName}
                    required
                  />
                </label>
                <label className="b3">
                  E-post:*
                  <input
                    className="b1"
                    type="email"
                    name="email"
                    placeholder="epost@eksempel.no"
                    defaultValue={profile?.email}
                    required
                  />
                </label>
                <label className="b3">
                  Telefon:*
                  <input
                    className="b1"
                    type="text"
                    name="phone"
                    autoComplete="tel-local"
                    placeholder="123 45 678"
                    defaultValue={profile?.phone}
                    required
                  />
                </label>
                <label className="b3">
                  Bedriftsnavn:*
                  <input
                    className="b1"
                    type="text"
                    name="company"
                    autoComplete="organization"
                    placeholder="Bedrift AS"
                    defaultValue={group?.name || profile?.company}
                    readOnly={!!group}
                    required
                  />
                </label>
                <label className="b3">
                  Organisasjonsnummer:
                  <input
                    className="b1"
                    type="text"
                    name="vat"
                    placeholder="123456789"
                    minLength={9}
                    maxLength={9}
                    defaultValue={group?.billing.vat || profile?.vat}
                    readOnly={!!group?.billing.vat}
                  />
                </label>
                <label className="b3">
                  Yrkesgruppe*:
                  <select
                    name="profession"
                    className="b1"
                    required
                    defaultValue={profile?.props.profession}
                  >
                    <option value="">Vennligst Velg</option>
                    <option value="Lysdesigner/Belysningsplanlegger">
                      Lysdesigner/Belysningsplanlegger
                    </option>
                    <option value="Arkitekt">Arkitekt</option>
                    <option value="Salg og markedsføring">
                      Salg og markedsføring
                    </option>
                    <option value="Elektriker/installatør">
                      Elektriker/installatør
                    </option>
                    <option value="RIE: rådgivende ingeniør elektro">
                      RIE: rådgivende ingeniør elektro
                    </option>
                    <option value="Annet">Annet</option>
                  </select>
                </label>
                {product.props.askForAllergies && (
                  <label className="b3">
                    Allergier:
                    <textarea className="b1" name="allergies" />
                  </label>
                )}
                {!courseIsFree && (
                  <>
                    <h3 className="h6">Fakturainfo</h3>
                    <label className="b3">
                      Fornavn:*
                      <input
                        className="b1"
                        type="text"
                        name="billingFirstName"
                        autoComplete="billing work given-name"
                        placeholder="Kari"
                        defaultValue={
                          group?.billing.firstName || profile?.firstName
                        }
                        readOnly={!!group?.billing.firstName}
                        required
                      />
                    </label>
                    <label className="b3">
                      Etternavn:*
                      <input
                        className="b1"
                        type="text"
                        name="billingLastName"
                        autoComplete="billing work family-name"
                        placeholder="Nordmann"
                        defaultValue={
                          group?.billing.lastName || profile?.lastName
                        }
                        readOnly={!!group?.billing.lastName}
                        required
                      />
                    </label>
                    <label className="b3">
                      E-post:*
                      <input
                        className="b1"
                        type="email"
                        name="billingEmail"
                        autoComplete="billing work email"
                        placeholder="epost@eksempel.no"
                        defaultValue={group?.billing.email || profile?.email}
                        readOnly={!!group?.billing.email}
                        required
                      />
                    </label>
                    <label className="b3">
                      Telefon:*
                      <input
                        className="b1"
                        type="text"
                        name="billingPhone"
                        autoComplete="billing work tel-local"
                        placeholder="123 45 678"
                        defaultValue={group?.billing.phone || profile?.phone}
                        readOnly={!!group?.billing.phone}
                        required
                      />
                    </label>
                    <label className="b3">
                      Adresse:*
                      <input
                        className="b1"
                        type="text"
                        name="addressLine1"
                        autoComplete="billing work address-line1"
                        placeholder="Norgeveien 1"
                        defaultValue={
                          group?.billing.addressLine1 || profile?.addressLine1
                        }
                        readOnly={!!group?.billing.addressLine1}
                        required
                      />
                    </label>
                    <label className="b3">
                      Postnummer:*
                      <input
                        className="b1"
                        type="text"
                        name="postcode"
                        autoComplete="billing work postal-code"
                        defaultValue={
                          group?.billing.postcode || profile?.postcode
                        }
                        readOnly={!!group?.billing.postcode}
                        required
                      />
                    </label>
                    <label className="b3">
                      Sted:*
                      <input
                        className="b1"
                        type="text"
                        name="city"
                        autoComplete="billing work address-level2"
                        defaultValue={group?.billing.city || profile?.city}
                        readOnly={!!group?.billing.city}
                        required
                      />
                    </label>
                    <label className="b3">
                      Innkjøpsnr./ faktura merkes med:
                      <input
                        className="b1"
                        type="string"
                        name="paymentReference"
                      />
                    </label>
                    <h3 className="h6">Tilbudskode</h3>
                    <label className="b3">
                      Tilbudskode
                      <input className="b1" type="text" name="discountCode" />
                    </label>
                  </>
                )}
                <Consents consents={consents} />
                <button
                  type="submit"
                  className="button secondary"
                  disabled={loading || success}
                >
                  {loading ? "Laster…" : <>Meld meg på {arrow}</>}
                </button>
              </form>
            ) : (
              <div className="button-area">
                <button
                  type="button"
                  className="secondary"
                  disabled={networking}
                  onClick={handleAddToCart}
                >
                  {networking ? "Laster…" : <>Legg i handlekurv {arrow}</>}
                </button>
              </div>
            )}
          </Busy>
        </div>
      </div>
      {(sidebarBlocks.length > 0 || testimonials.length > 0) && (
        <div className="column sidebar">
          {sidebarBlocks.length > 0 && (
            <div className="card">
              <h2 className="h6">
                <span>{sidebarTitle || "Fakta"}</span>
              </h2>
              {sidebarBlocks.map((block) => (
                <div key={block.id} className="block">
                  <Block block={block} />
                </div>
              ))}
            </div>
          )}
          {testimonials.map((testimonial) => {
            return (
              <Testimonial key={testimonial.id} testimonial={testimonial} />
            );
          })}
        </div>
      )}
    </div>
  );
};

const Block: React.FC<{ block: SidebarBlock }> = ({ block }) => {
  switch (block.key) {
    case "html":
      return block.html && <RichText html={block.html} />;
    case "link":
      return (
        block.link && (
          <a className="button secondary link" {...getLinkProps(block.link)}>
            {block.link.title} {arrow}
          </a>
        )
      );
    default:
      return null;
  }
};

const Gfx: React.FC<{ file?: File }> = ({ file }) => {
  const product = useStore().context.product;
  const _file = file || product?.primaryImage;

  if (!_file) {
    return null;
  }

  if (_file.mimeType.startsWith("video")) {
    const video = _file as File;

    return (
      <div className="block">
        <video
          className="gfx"
          controls
          preload={video.previewImage ? "none" : "auto"}
          poster={
            video.previewImage
              ? transformImageUrl(video.previewImage, { width: 1180 })
              : undefined
          }
        >
          <source src={video.url} type={video.mimeType} />
          Din nettleser støtter ikke denne video taggen.
        </video>
      </div>
    );
  }

  const image = _file as Image;

  return (
    <div className="block">
      <img
        className="gfx"
        src={transformImageUrl(image, { width: 1180 })}
        alt={image.altText}
      />
    </div>
  );
};

const Price: React.FC<{ text?: string }> = ({ text }) => {
  const context = useStore().context;
  const product = context.product;

  if (text) {
    return <RichText className="block price" html={text} />;
  }

  if (!product || !product.price) {
    return null;
  }

  if (product.priceEntries.length > 0) {
    return (
      <div className="block">
        <div className="rich-text price">
          <p>
            {product.priceEntries.map((entry) => (
              <span key={entry._id}>
                <b>
                  {entry.type === "default"
                    ? "Pris ikke-medlem"
                    : "Pris medlem"}
                  : {entry.priceText}
                  {renderTaxString(entry.tax, context.pricesIncludeTax)}
                </b>
                <br />
              </span>
            ))}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="block">
      <div className="rich-text price">
        <p>
          <b>
            Pris: {product.priceText}
            {renderTaxString(product.tax, context.pricesIncludeTax)}
          </b>
        </p>
      </div>
    </div>
  );
};

const Instructors: React.FC<{ title?: string; instructors: Instructor[] }> = ({
  title,
  instructors = [],
}) => {
  if (instructors.length === 0) {
    return null;
  }

  return (
    <div className="block">
      <div className="instructors">
        {title && <h2 className="h6">{title}</h2>}
        <div className="instructors-list">
          {instructors.map((instructor) => {
            if (!instructor.name) {
              return null;
            }

            return (
              <div key={instructor.id} className="instructor">
                {instructor.image && (
                  <img
                    src={transformImageUrl(instructor.image, {
                      width: 336,
                      height: 336,
                    })}
                    alt={instructor.image.altText}
                    loading="lazy"
                  />
                )}
                <div>
                  <h4 className="b1">{instructor.name}</h4>
                  {instructor.title && (
                    <p className="instructor-title">{instructor.title}</p>
                  )}
                  {instructor.description && (
                    <RichText html={instructor.description} />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Testimonial: React.FC<{ testimonial: Testimonial }> = ({
  testimonial,
}) => {
  return (
    <div className="testimonial">
      {testimonial.image && (
        <img
          src={transformImageUrl(testimonial.image, {
            width: 662,
            height: 448,
          })}
          alt={testimonial.image.altText}
          loading="lazy"
        />
      )}
      {testimonial.text && <p className="h6">{testimonial.text}</p>}
      <p className="byline">
        {testimonial.byline}
        {quote}
      </p>
    </div>
  );
};

const AddToCartModal: React.FC<{ item: CartItem }> = ({ item }) => {
  const { despawnModal } = useModal();
  const { product, totalText } = item;
  const title = "Produktet ble lagt til i handlekurven";

  return (
    <div className="cart-modal card" title={title}>
      <h1 className="h3">{title}</h1>
      {product.primaryImage && (
        <img
          src={transformImageUrl(product.primaryImage, {
            width: 476,
            height: 476,
            fit: "cover",
          })}
          alt={product.primaryImage.altText}
        />
      )}
      <div className="text">
        <h2 className="h4">
          <span>{product.name}</span>
        </h2>
        <p className="h6">
          {totalText} {product.priceSuffix}
        </p>
      </div>
      <div className="button-list">
        <button className="button primary" onClick={despawnModal}>
          Fortsett å handle
        </button>
        <a href="/handlekurv" className="button secondary">
          Gå til handlekurv {arrow}
        </a>
      </div>
    </div>
  );
};

export default Course;
